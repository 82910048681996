var BoyacinBenim = BoyacinBenim || {};
BoyacinBenim.Modules = BoyacinBenim.Modules || {};

BoyacinBenim.Modules.renderModules = (function ($) {
    var moduleElements, moduleID;

    var init = function () {
        gatherElements();
    };

    var gatherElements = function () {
        moduleElements = $('[data-module]').filter(function () {
            return $(this).data('module');
        });
        createModule(moduleElements);
    };

    var createModule = function (modules) {
        var $this;
        $.each(modules, function (index, el) {
            $this = $(el);
            moduleID = $this.data('module');

            if (BoyacinBenim.Modules['module_' + moduleID]) {
                $this.data('module_' + moduleID, (data = new BoyacinBenim.Modules['module_' + moduleID]($this, moduleID)));
            }
        });
    };

    return init;
})(jQuery);

BoyacinBenim.Modules.__module = (function ($) {

    var module = function (props) {
        var properties = props;

        var defaults = {
            "name": "Module"
        };

        this.settings = $.extend({}, defaults, properties);
        this.initialize();
    };

    module.prototype = {
        initialize: function () {
            this.elements = {};
            this.buildCache();
            this.addEventListener();
        },
        buildCache: function () {
            this.elements = {
                ID: this.settings.ID,
                $el: this.settings.el,
                name: this.settings.name
            };
        },
        showInfo: function () {
           /* alert('### MODULE INFORMATION ### \n ID: ' + this.elements.ID + '\n NAME: ' + this.elements.name);*/
        },
        addEventListener: function () {
            var _self = this;
            var timeoutId = 0;

            _self.elements.$el.mousedown(function (event) {
                if (event.which === 3) {
                    timeoutId = setTimeout(function () {
                        _self.showInfo();
                    }, 1000);
                }
            }).bind('mouseup mouseleave', function () {
                if (timeoutId)
                    clearTimeout(timeoutId);
            });
        }
    };

    return module;

})(jQuery);

$(function () {
    BoyacinBenim.Modules.renderModules();
})