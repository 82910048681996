var MODULES = MODULES || {};
MODULES.Utility = {};

MODULES.Utility.GLOBAL = (function ($) {
    var info = "GLOBAL MODULE";
    var _self = this;

    (function lazyLoad() {
        $(window).scroll(function(){
            $('.lazy').each(function(){
                if( $(this).offset().top < ($(window).scrollTop() + $(window).height() + 100) ) {
                    $(this).removeClass('lazy').attr('src', $(this).attr('data-src'));
                }
            });
        });
    })();

    (function modalForm() {

        $("a.yorum-btn").on('click', function() {
            $('#yorumForm').modal('show');
        });

        $('#yorumYaz').on('click', function() {
            if($('#yorumForm form').valid()) {
                $('#yorumForm form label.error').remove();
                var data = {
                    adsoyad: $('#yorumForm input[name=adsoyad]').val(),
                    email: $('#yorumForm input[name=email').val(),
                    yorum: $('#yorumForm textarea[name=yorum]').val(),
                    _token: $('meta[name="csrf-token"]').attr('content')
                };
                $.ajax({
                    type: "POST",
                    url: "/yorumform",
                    data: data
                }).done(function(res) {
                    $('#yorumForm').modal('hide');
                    $('#yorumForm input, #yorumForm textarea').val('');
                    var htmlError = '<i class="icon-success"></i>' +
                        '<h5>BAŞARILI!</h5>' +
                        '<p>'+ res.response +'</p>';
                    $('#errorModal .modal-body').html(htmlError);
                    $('#errorModal').modal('show');
                }).fail(function(res) {
                    $('#yorumForm form').prepend('<label class="error">'+ res.responseJSON.response +'</label>');
                });
            }

            return false;
        });

        $("a.soru-btn, a.soru-sor-btn").on('click', function() {
            $('#soruForm').modal('show');
        });

        $('#soruSor').on('click', function() {
            if($('#soruForm form').valid()) {
                $('#soruForm form label.error').remove();
                var data = {
                    adsoyad: $('#soruForm input[name=adsoyad]').val(),
                    telefon: $('#soruForm input[name=telefon]').val(),
                    email: $('#soruForm input[name=email').val(),
                    soru: $('#soruForm textarea[name=soru]').val(),
                    _token: $('meta[name="csrf-token"]').attr('content')
                };
                $.ajax({
                    type: "POST",
                    url: "/soruform",
                    data: data
                }).done(function(res) {
                    $('#soruForm').modal('hide');
                    $('#soruForm input, #soruForm textarea').val('');
                    var htmlError = '<i class="icon-success"></i>' +
                        '<h5>BAŞARILI!</h5>' +
                        '<p>'+ res.response +'</p>';
                    $('#errorModal .modal-body').html(htmlError);
                    $('#errorModal').modal('show');
                }).fail(function(res) {
                    $('#soruForm form').prepend('<label class="error">'+ res.responseJSON.response +'</label>');
                });
            }

            return false;
        });
    })();

    (function service() {
        $(".sub-page .category a.all").on('click', function() {
            if($(".sub-page .category").hasClass('active')) {
                $(".sub-page .category").removeClass('active');
                $(".sub-page .category a.all").text('Tümünü İncele');
            } else {
                $(".sub-page .category").addClass('active');
                $(".sub-page .category a.all").text('Daha Az Göster');
            }
        });

        $(".page-head a.all-text").on('click', function() {
            if($(".page-head").hasClass('active')) {
                $(".page-head").removeClass('active');
                $(".page-head .text").slideUp(500);
            } else {
                $(".page-head").addClass('active');
                $(".page-head .text").slideDown(500);
            }
        });

        $(".services .menu > p").on('click', function() {
            $(this).closest('.menu').toggleClass('active');
        });
    })();

    (function listHeight() {
        setTimeout(function() {
            var n = -1;
            $(".list .item").css({
                height: "inherit"
            });
            $(".list").each(function() {
                $(this).find(".item").each(function() {
                    var t = $(this).height();
                    t > n && (n = t)
                });
                $(this).find(".item").height(n);
            });
        }, 1000);
    })();

    (function project() {
        $('[data-fancybox]').fancybox({
            protect: true
        });
    })();

    (function contact(){
        $('#btnSubmit').on('click', function() {
            if($('#contactForm form').valid()) {
                $('#contactForm form label.error').remove();
                var data = {
                    adsoyad: $('#contactForm input[name=adsoyad]').val(),
                    email: $('#contactForm input[name=email').val(),
                    telefon: $('#contactForm input[name=telefon').val(),
                    mesaj: $('#contactForm textarea[name=mesaj]').val(),
                    _token: $('meta[name="csrf-token"]').attr('content')
                };
                $.ajax({
                    type: "POST",
                    url: "/contactform",
                    data: data
                }).done(function(res) {
                    $('#contactForm input, #contactForm textarea').val('');
                    var htmlError = '<i class="icon-success"></i>' +
                        '<h5>BAŞARILI!</h5>' +
                        '<p>'+ res.response +'</p>';
                    $('#errorModal .modal-body').html(htmlError);
                    $('#errorModal').modal('show');
                }).fail(function(res) {
                    var htmlError = '<i class="icon-close"></i>' +
                        '<h5>BAŞARISIZ!</h5>' +
                        '<p>'+ res.responseJSON.response +'</p>';
                    $('#errorModal .modal-body').html(htmlError);
                    $('#errorModal').modal('show');
                });
            }
            return false;
        });
    })()

})(jQuery);