if ($.validator) {
    $.extend($.validator.messages, {
        required: "Bu alanın doldurulması zorunludur.",
        remote: "Lütfen bu alanı düzeltin.",
        email: "Lütfen geçerli bir e-posta adresi giriniz.",
        url: "Lütfen geçerli bir web adresi (URL) giriniz.",
        date: "Lütfen geçerli bir tarih giriniz.",
        dateISO: "Lütfen geçerli bir tarih giriniz(ISO formatında)",
        number: "Lütfen geçerli bir sayı giriniz.",
        digits: "Lütfen sadece sayısal karakterler giriniz.",
        creditcard: "Lütfen geçerli bir kredi kartı giriniz.",
        equalTo: "Lütfen aynı değeri tekrar giriniz.",
        extension: "Lütfen geçerli uzantıya sahip bir değer giriniz.",
        maxlength: $.validator.format("Lütfen en fazla {0} karakter uzunluğunda bir değer giriniz."),
        minlength: $.validator.format("Lütfen en az {0} karakter uzunluğunda bir değer giriniz."),
        rangelength: $.validator.format("Lütfen en az {0} ve en fazla {1} uzunluğunda bir değer giriniz."),
        range: $.validator.format("Lütfen {0} ile {1} arasında bir değer giriniz."),
        max: $.validator.format("Lütfen {0} değerine eşit ya da daha küçük bir değer giriniz."),
        min: $.validator.format("Lütfen {0} değerine eşit ya da daha büyük bir değer giriniz."),
        step: $.validator.format("Lütfen {0} değerinin katı sayı giriniz."),
        require_from_group: "Lütfen bu alanların en az {0} tanesini doldurunuz.",
        txttckn: "Lütfen geçerli bir TCKN giriniz.",
        txtvkn: "Lütfen geçerli bir VKN giriniz.",
        phone: "Lütfen geçerli bir Telefon No giriniz.",
        birthday: "Lütfen geçerli bir tarih giriniz.",
        creditcard: 'Lütfen geçerli bir Kredi Kartı giriniz.',
        filesize: 'Maksimum 3 Mb dosya eklenebilir.',
        emailconfirm: "Lütfen aynı değeri tekrar giriniz."
    });
}

var patternEmail = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;

$.validator.addMethod("isEmail", function (value) {
    return patternEmail.test(value);
}, jQuery.validator.messages.email);

jQuery.validator.addClassRules({
    required: {
        required: true,
    },
    email: {
        required: true,
        isEmail: true
    }
});

jQuery.validator.setDefaults({
    ignore: ".ignore, :hidden",
    errorClass: "error",
    errorElement: "label",
    highlight: function (element, errorClass) {
        $(element).addClass("error");
    },
    unhighlight: function (element) {
        $(element).removeClass("error");
    }
});

$('.phone').mask('(000)-000-00-00');