BoyacinBenim.Modules.module_MainNavigation = (function ($) {
    var module = function (el, id) {

        var properties = {
            "el": el,
            "ID": id,
            "name": "Main Navigation"
        };

        BoyacinBenim.Modules.__module.call(this, properties);
        this.init();
    };

    module.prototype = Object.create(BoyacinBenim.Modules.__module.prototype);

    $.extend(module.prototype, {
        init: function () {
            this.moduleCache();
            this.eventListener();
        },
        moduleCache: function () {
            this.elements.$body = $('body');
            this.elements.$mobileBtn = this.elements.$el.find('.mobile-menu');
        },
        eventListener: function(){
            var _this = this;
            this.elements.$mobileBtn.on('click', function() {
                _this.elements.$mobileBtn.toggleClass('open');
                _this.elements.$el.toggleClass('nav-active ');
                _this.elements.$body.toggleClass('nav-open');
            });
        }
    });

    return module;
})(jQuery);

BoyacinBenim.Modules.module_HomeBanner = (function ($) {
    var module = function (el, id) {

        var properties = {
            "el": el,
            "ID": id,
            "name": "Home Banner"
        };

        BoyacinBenim.Modules.__module.call(this, properties);
        this.init();
    };

    module.prototype = Object.create(BoyacinBenim.Modules.__module.prototype);

    $.extend(module.prototype, {
        init: function () {
            this.moduleCache();
            this.eventListener();
        },
        moduleCache: function () {
            this.elements.$homeBanner = this.elements.$el.find('.owl-carousel');
            this.elements.$homeBg = this.elements.$el.find('.background');
            this.elements.$smallBanner = this.elements.$el.find('.small-banner');
        },
        eventListener: function(){
            var _this = this;
            this.elements.$homeBanner.owlCarousel({
                animateOut: 'fadeOutUp',
                animateIn: 'fadeInDown',
                mouseDrag:false,
                touchDrag:false,
                singleItem: true,
                items:1,
                autoplay:true,
                autoplayTimeout:8000,
                autoplayHoverPause:true,
                loop:true,
                dots: false,
                autoHeight:true,
                responsive: {
                    0: {
                        mouseDrag:true,
                        touchDrag:true
                    },
                    992: {
                        mouseDrag:false,
                        touchDrag:false
                    }
                },
                onInitialized: function(event) {
                    var bg = _this.elements.$homeBanner.find('.owl-item.active').find('.item').attr('data-bg');
                    _this.elements.$homeBg.removeClass('active').css('background-image', 'url("'+ bg +'")').addClass('active');
                    _this.elements.$smallBanner.find('.item').removeClass('active');
                    _this.elements.$smallBanner.find('.item:first-child').addClass('active');
                },
                onTranslated: function(event) {
                    var bg = _this.elements.$homeBanner.find('.owl-item.active').find('.item').attr('data-bg');
                    _this.elements.$homeBg.removeClass('active');
    
                    setTimeout(function(){
                        _this.elements.$homeBg.css('background-image', 'url("'+ bg +'")').addClass('active');
                    },1);
    
                    var itemIndex = $('.owl-item.active').find('.item').attr('data-index');
                    _this.elements.$smallBanner.find('.item').removeClass('active');
                    _this.elements.$smallBanner.find('.item[data-index="'+ itemIndex +'"]').addClass('active');
                    if(itemIndex === "0") {
                        _this.elements.$smallBanner.animate({scrollTop: 0}, 'slow');
                    } else {
                        var scrollTopTotal = (parseInt(itemIndex) * ($('.small-banner .item[data-index="'+ itemIndex +'"]').outerHeight() + 30));

                        _this.elements.$smallBanner.animate({scrollTop: scrollTopTotal}, 'slow');
                    }
                }
            });

            $('.small-banner .item').on('click', function() {
                var itemIndex = parseInt($(this).attr('data-index'));
                _this.elements.$homeBanner.trigger("to.owl.carousel", [itemIndex, 300])
            });
    
            $('.small-banner .item.active .close').on('click', function() {
                _this.elements.$homeBanner.trigger("next.owl.carousel", [300]);
            });
        }
    });

    return module;
})(jQuery);

BoyacinBenim.Modules.module_HomeComment = (function ($) {
    var module = function (el, id) {

        var properties = {
            "el": el,
            "ID": id,
            "name": "Home Comment"
        };

        BoyacinBenim.Modules.__module.call(this, properties);
        this.init();
    };

    module.prototype = Object.create(BoyacinBenim.Modules.__module.prototype);

    $.extend(module.prototype, {
        init: function () {
            this.moduleCache();
            this.eventListener();
        },
        moduleCache: function () {
            this.elements.$mainComment = this.elements.$el.find('.owl-carousel');
        },
        eventListener: function(){
            this.elements.$mainComment.owlCarousel({
                animateOut: 'fadeOutUp',
                animateIn: 'fadeInDown',
                singleItem: true,
                items:1,
                autoplay:true,
                autoplayTimeout:3000,
                autoplayHoverPause:true,
                loop:true,
                dots: false,
                autoHeight:true
            });
        }
    });

    return module;
})(jQuery);

BoyacinBenim.Modules.module_ScrollAnimate = (function ($) {
    var module = function (el, id) {

        var properties = {
            "el": el,
            "ID": id,
            "name": "Scroll Animate"
        };

        BoyacinBenim.Modules.__module.call(this, properties);
        this.init();
    };

    module.prototype = Object.create(BoyacinBenim.Modules.__module.prototype);

    $.extend(module.prototype, {
        init: function () {
            var _this = this;
            this.moduleCache();
            this.eventListener(_this);

            $(window).scroll(function() {
                _this.eventListener(_this);
            });

        },
        moduleCache: function () {
            this.elements.$body = $('body');
            this.elements.$htmlBody = $('html,body');
            this.elements.$homeBanner = this.elements.$el.find('.home-banner');
            this.elements.timer = null;
        },
        eventListener: function(_this){
            if ($(window).scrollTop() > 20) {
                if (!_this.elements.$body.hasClass('scrolled')) {
                    _this.elements.$homeBanner.addClass('animated fadeOut');
                    _this.elements.$htmlBody.addClass('scrolled');
                    _this.elements.timer = setTimeout(function() {
                        _this.elements.$htmlBody.animate({
                            'scrollTop': $(window).height()
                        }, 600);
                    }, 1);
                }
            } else {
                if (_this.elements.timer != null) {
                    clearTimeout(this.elements.timer);
                    _this.elements.timer = null;
                }
                if (_this.elements.$body.hasClass('scrolled')) {
                    _this.elements.$homeBanner.removeClass('fadeOut').addClass('fadeIn');
                    _this.elements.$htmlBody.removeClass('scrolled');
                }
            }
        }
    });

    return module;
})(jQuery);

BoyacinBenim.Modules.module_Search = (function ($) {
    var module = function (el, id) {

        var properties = {
            "el": el,
            "ID": id,
            "name": "Search"
        };

        BoyacinBenim.Modules.__module.call(this, properties);
        this.init();
    };

    module.prototype = Object.create(BoyacinBenim.Modules.__module.prototype);

    $.extend(module.prototype, {
        init: function () {
            this.moduleCache();
            this.eventListener();
        },
        moduleCache: function () {
            this.elements.$form = this.elements.$el.find('.form');
            this.elements.$input = this.elements.$form.find('input');
            this.elements.$button = this.elements.$form.find('button');
            this.elements.$search__html = this.elements.$el.find('.search__html');
            this.elements.$search__title = this.elements.$el.find('.search__title');
        },
        debounce: function (func, wait, immediate) {
            var timeout;
            return function () {
                var context = this, args = arguments;
                var later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        },
        eventListener: function(){
            var _this = this;

            $(document).on('click', 'header a.search', function() {
                if(_this.elements.$el.hasClass('active')) {
                    _this.elements.$el.removeClass('active');
                    _this.elements.$el.slideUp();
                    $('header a.search i').removeClass('icon-close').addClass('icon-search');
                    $('header a.search span').text('SİTEDE ARA');
                } else {
                    _this.elements.$el.addClass('active');
                    _this.elements.$el.slideDown();
                    $('header a.search i').removeClass('icon-search').addClass('icon-close');
                    $('header a.search span').text('KAPAT');
                }
            });

            _this.elements.$input.keyup(_this.debounce(function (event) {
                if ($(this).val().length >= 3 && event.which != 38 && event.which != 40) {
                    _this.elements.$form.addClass('loading');
                    _this.getAjaxData($(this).val());
                } else {
                    _this.elements.$search__html.addClass('hide');
                    _this.elements.$search__title.addClass('hide');
                    _this.elements.$form.removeClass('loading');
                }
            }, 1000));
        },
        getAjaxData: function(value) {
            var _this = this;
            $.ajax({
                url: '/api/search/' + value,
                type: 'GET',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Cache-Control": "no-cache",
                },
                success: function (result) {
                    _this.elements.$form.removeClass('loading');
                    _this.getHtml(result, value);
                }
            }).fail(function() {
                _this.elements.$search__html.addClass('hide');
                _this.elements.$search__title.addClass('hide');
            });
        },
        getHtml: function(result, value) {
            var html = '';
            this.elements.$search__title.removeClass('hide').find('span').html("ARAMA SONUÇLARI");
            $.each(result.services, function(i, item) {
                html += '<div class="col-md-4">' +
                    '<a href="'+ item.slug +'">'+ item.baslik +'</a>' +
                '</div>';
            });
            $.each(result.question, function(i, item) {
                html += '<div class="col-md-4">' +
                    '<a href="'+ item.slug +'">'+ item.baslik +'</a>' +
                '</div>';
            });
            this.elements.$search__html.removeClass('hide').html(html);
        }
    });

    return module;
})(jQuery);